import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import { Typography, Grid } from '@material-ui/core';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { PanelType, SET_CURRENT_ACTIVITY } from '../../redux/panels/types';
import useValidateSubscriptionOnClick from '../BillingStatus/validateBillingOnClick';
import ValidateSubscriptionStyle from '../BillingStatus/validateBillingStyle';
import { showInfoClicked } from '../../redux/app/actions';

const useStyles = makeStyles(() =>
    createStyles({
        card: {
            height: 146,
            width: 146,
            borderRadius: 5,
            cursor: 'pointer',
        },
        name: {
            color: '#0059A6',

            fontSize: 16,
        },

        background: {
            backgroundImage: "url('assets/panel.png') 0% 0% no-repeat padding-box",
        },
    }),
);

export default function Panel({ name, description, logo, id, media, ingredients }: PanelType) {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const [imgSrc, setImgSource] = React.useState('assets/menu/');

    const handleOnClick = () => {
        dispatch({
            type: SET_CURRENT_ACTIVITY,
            payload: { panel: { name, description, logo, id, media, ingredients } },
        });
        dispatch(showInfoClicked(false));
        history.push(`panel-info/${id}`);
        // eslint-disable-next-line no-alert
    };
    const validRedirectOnClick = useValidateSubscriptionOnClick({
        handler: handleOnClick,
    });
    return (
        <Card
            className={classes.card}
            onClick={validRedirectOnClick}
            // onMouseEnter={() => setImgSource('assets/menu/hover')}
            // onMouseLeave={() => setImgSource('assets/menu/')}
        >
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                style={ValidateSubscriptionStyle()}
            >
                <Grid item>
                    <img
                        id="img"
                        src={`${imgSrc}new_${logo}.png`}
                        style={{ marginTop: 10, maxWidth: 95, borderRadius: '10%' }}
                        height={85}
                        alt={description}
                        // onMouseEnter={() => setImgSource('assets/menu/hover')}
                        // onMouseLeave={() => setImgSource('assets/menu/')}
                    />
                </Grid>
                <Grid item>
                    <Typography
                        className={classes.name}
                        variant="body2"
                        color="textSecondary"
                        component="p"
                    >
                        {name}
                    </Typography>
                </Grid>
            </Grid>
        </Card>
    );
}
