import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Grid, makeStyles, Theme } from '@material-ui/core';

import MainLayout from '../../layout/MainLayout';
import Panels from '../../components/Panel/panels';
import { GlobalStateType } from '../../redux/rootTypes';
import { listPanelAction } from '../../redux/panels/actions';
import useOnlineDispatch from '../../components/OnlineDispatch';

const useStyle = makeStyles<Theme>((theme) => ({
    rootLinear: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(3),
        },
    },
    bg: {
        background: `url('../assets/bg.png')`,
        backgroundSize: 'cover',
        minHeight: '100vh',
        height: 'auto',
        width: '100vw',

        marginRight: '-20px !important',
        marginLeft: '-20px !important',
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
        },
    },
}));

const Home = () => {
    const onlineDispatch = useOnlineDispatch();
    const classes = useStyle();
    const { panels, network } = useSelector((state: GlobalStateType) => state);

    useEffect(() => {
        onlineDispatch(listPanelAction());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [network]);

    return (
        <MainLayout>
            <div className={classes.bg}>
                <Grid container justify="center" alignItems="flex-start">
                    <Panels panels={panels?.data} />{' '}
                </Grid>
            </div>
        </MainLayout>
    );
};

export default Home;
