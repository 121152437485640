import React, { useRef, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import ReCAPTCHA from 'react-google-recaptcha';
import validateGoogleToken from '../../redux/app/api';
import { ColorCircularProgress } from '../Loader';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: '#FFFFFF',
    },
    avatar: {
        margin: theme.spacing(1),
        width: '210px',
        height: '289px',
    },
}));

interface CaptchaLoaderType {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    setShowPage: any;
}

export default function CaptchaLoader({ setShowPage }: CaptchaLoaderType) {
    const classes = useStyles();
    const reRef = useRef<ReCAPTCHA>(null);
    const [showLoader, setShowLoader] = useState(false);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const validateToken = async (token: string) => {
        try {
            const data = await validateGoogleToken(token);
            if (!data || data.error) {
                // eslint-disable-next-line no-console
                console.warn(data ? data.error : 'Empty data result from reCaptcha');
                setShowPage(false);
            } else {
                setShowPage(true);
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.warn(error);
        }
    };

    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    const verifyCaptcha = (res: any) => {
        if (res) {
            // validateToken(res);
            setShowLoader(true);
            setShowPage(true);
        }
    };

    // ReCAPTCHA Expired
    const expireCaptcha = () => {
        setShowPage(false);
        setShowLoader(false);
    };

    return (
        <div className={classes.root}>
            <Container maxWidth="xs">
                <div className={classes.paper}>
                    <Avatar variant="square" className={classes.avatar} src="/logo.png" />
                    <ReCAPTCHA
                        sitekey={process.env.REACT_APP_GOOGLE_reCAPTCHA_KEY || ''}
                        ref={reRef}
                        onChange={verifyCaptcha}
                        onExpired={expireCaptcha}
                    />
                    {showLoader && <ColorCircularProgress />}
                </div>
            </Container>
        </div>
    );
}
